import React, { useEffect } from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaDownload, FaWhatsapp } from 'react-icons/fa';
import './ThankYouPage.css';
import { FaLinkedin, FaLinkedinIn } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { IoLogoYoutube } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { BiMap } from 'react-icons/bi';
import { BsClock } from 'react-icons/bs';

 
const ThankYouPage = () => {
    const location = useLocation()
 
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '../images/digitalmarketing.pdf';
        link.download = 'digitalmarketing.pdf';
        link.click();
    };
 
    const trackConversion = () => {
        if (window.gtag) {
            window.gtag('event', 'conversion', {
                send_to: 'AW-11395333265/pZodCNvboMkZEJH527kq'
            });
        }
    };
 
    useEffect(() => {
        if (location.pathname == '/thankyu')
            trackConversion()
    }, [location])
 
 
    return (
        <>
        <div className="container">
            <div className="content">
                <h1 className="title">Thank You!</h1>
                <p className="description">
                    We appreciate your interest. Stay connected with us on social media, download our curriculum, or visit our website for more information.
                </p>
                <p className="additional-info">
                    Someone from our team will be in touch with you shortly.
                </p>
                <div className="buttons">
                    <button className="download-button" onClick={handleDownload}>
                        <FaDownload className="button-icon" /> Download Curriculum
                    </button>
                    <a className="whatsapp-link" href="https://chat.whatsapp.com/CH1BG9HYkFW8DuleQJkmvZ" target="_blank" rel="noopener noreferrer">
                        <button className="whatsapp-button">
                            <FaWhatsapp className="button-icon whatsapp-icon" /> Join WhatsApp Channel
                        </button>
                    </a>
                </div>
                <div className="social-media">
                    <a className="social-link" href="https://www.facebook.com/techubyabilio" target="_blank" rel="noopener noreferrer">
                        <FaFacebook className="social-icon facebook-icon" />
                    </a>
                    <a className="social-link" href="https://www.instagram.com/techu_by_abilio/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="social-icon instagram-icon" />
                    </a>
                    <a className="social-link" href="https://www.youtube.com/@Tech_U820/featured" target="_blank" rel="noopener noreferrer">
                        <FaYoutube className="social-icon youtube-icon" />
                    </a>
                    <a className="social-link" href="https://www.linkedin.com/in/techu-by-abilio-a105b731b/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn className="social-icon linkedin-icon" />
                    </a>
                </div>
            </div>
        </div>
        <div className='footer-main'>
        <div className="digital-marketing-footer-main">
        <div className="digital-marketing-footer-inner ">
          <div className="footer-left">
            <div className="footer-1">
              <h1 className="footer-heading">
                Head Office: Madhapur, Hyderabad
              </h1>
              <p className="footer-para">
                TechU - Digital Marketing Courses In Hyderabad, Training
                Institute
              </p>
              <div className="footer-left-ul-elements-div">
                <ul className="footer-left-ul-elements">
                  <li>
                    <BiMap icon={BiMap} size={20} className="footer-icon-map" />
                    <span className="footer-li-span">
                      Address:TechU, #303B Images Capital Park, Madhapur,
                      Hyderabad - 500081
                    </span>
                  </li>
                  <li>
                    <BsClock
                      icon={BsClock}
                      size={20}
                      className="footer-icon-clock"
                    />
                    <span className="footer-li-span">
                      Mon To Fri 9:30 AM – 06:30 PM Visiting Hours / Enquiry
                    </span>
                  </li>
                  <li>
                    <a
                      href="mailto:techutraining@gmail.com"
                      className="em-btn tbtn inline-div "
                    >
                      <div className="footer-email">
                        <div className="em-icon ">
                          <MdEmail className="footer-icon-clock" />
                        </div>
                        <div className="wa-link">techutraining@gmail.com</div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="block-div-2">
                      <a
                        href="tel:+919154747782"
                        target="_blank"
                        className="wa-btn tbtn inline-div"
                      >
                        <div className="wa-icon">
                          <FaWhatsapp
                            style={{
                              color: "#ffffff",
                              background:
                                "linear-gradient(#23b33a, #23b33a, #23b33a)",
                              borderRadius: "50%",
                              width: "25px",
                              height: "25px",
                              padding: "0px",
                            }}
                          />
                        </div>
                        <div>
                          <div className="wa-link">
                            +91 9154747782 {"  "}(WhatsApp only)
                          </div>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li className='map-li'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2656437335995!2d78.3836042743742!3d17.446995183450333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf75a6036f38d0d1%3A0x5136a7418cf52179!2sTechU%20by%20Abilio!5e0!3m2!1sen!2sin!4v1723199583467!5m2!1sen!2sin"  className='gmap' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-3">
              <ul>
                <h1 className="footer-heading-links-ul">Quick Links:</h1>
                <li className="footer-heading-links-li">
                  <a href="#">Online Digital Marketing Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">Classroom Digital Marketing Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">SEO Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">Graphic Designing Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">Masters Digital Marketing Course</a>
                </li>
              </ul>
            </div>
            <div className="footer-3">
              <ul>
                <h1 className="footer-heading-links-ul">Useful Links:</h1>
                <li className="footer-heading-links-li">
                  <a href="#Testimonials"> Our Placement</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#Testimonials">Students Feedback</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="https://techu.in/" target="_blank">
                    About Us
                  </a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="https://techu.in/#/privacy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
              <div className="footer-logos">
                <a
                  href="https://www.facebook.com/techubyabilio"
                  target="_blank"
                >
                  <FaFacebook
                    style={{
                      color: "#ffffff",
                      background: "#4267B2",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      padding: "0px",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/techu_by_abilio/"
                  target="_blank"
                >
                  <FaInstagram
                    style={{
                      color: "#ffffff",
                      background:
                        "linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      padding: "5px",
                    }}
                  />
                </a>

                <a
                  href="https://www.youtube.com/@Tech_U820/featured"
                  target="_blank"
                >
                  <IoLogoYoutube
                    style={{
                      color: "#FF0000",
                      background: "#ffffff",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      padding: "5px",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/techu-by-abilio-a105b731b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank"
                >
                  <FaLinkedin
                    size={25}
                    style={{
                      color: "#ffffff",
                      // background: "#ffffff",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
        
        </>
       
    );
};
 
export default ThankYouPage;