import React, { useState } from 'react';
import TechuHome from './TechuHome';
import TechUFooter from "./TechUFooter"
import TechuCompany from "./TechuCompany"
const Main = ({handle}) => {
    const handleclick=(text)=>{
        handle(text)
    }
    return (
        <div className="App">
            <TechuHome handleclick={handleclick} />
           <TechuCompany handleclick={handleclick}/>
            <TechUFooter handleclick={handleclick}/>
        </div>
    );
}

export default Main;
