import React, { useRef, useState } from "react";
import "./techuhome.css";

import { IoMenuOutline } from "react-icons/io5";
import {
  FaChevronDown,
  FaDownload,
  FaRegCheckCircle,
  FaStar,
  FaWhatsapp,
} from "react-icons/fa";

// import googleImg from "./images/google-img.png";
import Profile from "./images/Profile.jpg";

// testmonial-images

import testmonialimg1 from "./images/reviews-Image1.png";
import testmonialimg2 from "./images/reviews-Image2.png";
import testmonialimg3 from "./images/reviews-Image3.png";
import testmonialimg4 from "./images/reviews-Image4.png";
import testmonialimg5 from "./images/reviews-Image5.png";
import testmonialimg6 from "./images/reviews-Image6.png";
// trainer-images
import trainer from "./images/tainer.png"
// featured icons
import certificateIcon from "./images/certificateIcon.png";
import intershipIcon from "./images/intershipIcon.png";
import courseDurationIcon from "./images/courseDurationIcon.png";
import eligibilityIcon from "./images/eligibilityIcon.png";

import livesections from "./images/livesections.png";
import oneOnOne from "./images/oneOnOne.png";
import plecement from "./images/placement.png";
import softskills from "./images/softskills.png";
import mockinterviews from "./images/mockInterviews.png";
import merntoring from "./images/Mentersuport.png";
import Masterclasses from "./images/Masterclasses.png";
import axios from "axios";
import formStudent from "./images/demo-embed-form-person-image.jpg";
import { FiPhone } from "react-icons/fi";
// import { IoLogoYoutube } from "react-icons/io";
// import { FaFacebookSquare } from "react-icons/fa";
import googleImg from "./images/Google.png";
import facebookImg from "./images/facebook-logo-img.png";
import hostpostImg from "./images/hubsport-img.png";
import youtubeImg from "./images/youtube-logo.png";
import { useNavigate } from "react-router-dom";

const TechuHome = ({ handleclick }) => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const handleToggle = () => {
    setShowAll(!showAll);
  };
  const targetFieldRef = useRef(null);
  const handleClick = () => {
    if (targetFieldRef.current) {
      targetFieldRef.current.focus();
    }


  };
  const [activeTab, setActiveTab] = useState("Tab 1");
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    nativeState: "",
    education: "",
    course: "digitalMarketing",
  });

  const tabsData = [
    { id: "Tab 1", label: "Fundamentals" },
    { id: "Tab 2", label: "Digital Marketing" },
  ];
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const accordionData = {
    "Tab 1": [
      {
        id: "flush-collapseOne",
        title: "Digital Marketing Strategy",
        content: [
          [
            "Set goals",
            "Define your audience",
            "Conduct a competitive analysis",
            "Conduct a SWOT analysis",
            "Determine your budget",
          ],
          [
            "Identify your channels",
            "Develop specific tactics",
            "5 best Digital Marketing Strategy tools",
            "Create a calendar",
          ],
        ],
      },
      {
        id: "flush-collapseTwo",
        title: "Search Engine Optimization (SEO)",
        content: [
          [
            "Introduction",
            "Optimize Web Pages",
            "Keyword Research",
            "Technical SEO",
            "Strategy to Increase Relevance",
            "Traffic & Leads",
          ],
          [
            "Link Building Strategy 2022",
            "Generating Backlinks to Get Leads",
            "Case Studies: How to Increase Leads, Traffic & Rankings",
            "Pros & Cons of SEO",
            "Download SEO Templates",
          ],
        ],
      },
      {
        id: "flush-collapseThree",
        title: "Importance of Leads Audience & Funnels",
        content: [
          [
            "What is lead generation?",
            "How to find a target audience",
            "Different types of leads",
            "Quantity vs. Quality Leads",
            "Lead scoring and its importance",
            "Understanding the Sales Funnel",
          ],
        ],
      },
      {
        id: "flush-collapseFour",
        title: "Mastering in Designing & Videos that Sell",
        content: [
          [
            "Top 3 Designing Software for Digital Marketers",
            "Top 4 Video Creating Software for Digital Marketers",
            "Top 4 Sites Where You Can Sell Your Skills",
          ],
        ],
      },
      {
        id: "flush-collapseFive",
        title: "Create Website in 90 Mins",
        content: [
          [
            "Introduction",
            "WordPress.com vs WordPress.org",
            "Free WordPress Hosting Sites",
            "Free WordPress Themes",
            "Installing WordPress Themes",
          ],
          [
            "Customize Your WordPress Theme",
            "Creating Menus, Pages & Posts",
            "Finding Best SEO Plugins",
            "How to Install Plugins",
          ],
        ],
      },
      {
        id: "flush-collapseSix",
        title: "Google Ads",
        content: [
          [
            "What is Google Lead Ads?",
            "Understanding Google Ads Account",
            "Campaigns, Ad Groups & Ads",
            "Types of Ads, Placements & Ad Copy",
            "Creating Campaigns",
          ],
          [
            "Optimizing Ads & Calls",
            "Conversion Tracking",
            "Profitable Bidding Strategies",
            "Secret Weapon to Convert Like a Pro (Remarketing)",
            "Closures",
          ],
        ],
      },
      {
        id: "flush-collapseSeven",
        title: "Facebook & Instagram Ads ",
        content: [
          [
            "Introduction to Facebook & Instagram Ads",
            "How FB & Instagram Algorithms Work",
            "Facebook Advertising Policies",
            "Audience Targeting",
            "Facebook Campaign Structure",
          ],
          [
            "Inspirational Ad Creative",
            "Ad Copywriting & Tracking",
            "Secret Weapon to Convert Like a Pro ",
            "Dashboard & Reports",
          ],
        ],
      },
      {
        id: "flush-collapseEight",
        title: "YouTube Video Ads",
        content: [
          [
            "Intro to YouTube Ads",
            "Linking YouTube Ads and Accounts",
            "Types of Video Ads",
            "Identify Your Target Audience on YouTube",
            "Scriptwriting & Storytelling for Your Ads",
            "Project Setup",
          ],
          [
            "Campaign Setup, Optimization",
            "Advanced Ad Sequence Strategies",
            "Creating YouTube Ads",
            "Measuring YouTube Ads",
            "Secret Weapon to Convert Like a Pro ",
            "Closures",
          ],
        ],
      },
      {
        id: "flush-collapseNine",
        title: "LinkedIn Ads",
        content: [
          [
            "What are LinkedIn Ads?",
            "Setting Up LinkedIn Ads Account",
            "Types of Ads",
            "Identify Audience",
            "Creating LinkedIn Ads",
          ],
          [
            "How to Measure LinkedIn Ads",
            "B2B & B2C Targeting",
            "Secret Weapon to Converting Like a Boss ",
            "Closures",
          ],
        ],
      },
      {
        id: "flush-collapseTen",
        title: "Quora Marketing",
        content: [
          [
            "What is Quora?",
            "3 Ways It Will Grow Your Business",
            "How to Join Quora",
            "Optimize Your Quora Profile",
            "How to Identify Questions That Are Worth Answering",
          ],
          [
            "Craft a Post to Link Back",
            "Structure Your Answers for Maximum Clicks",
            "How to Promote Your Quora Answers",
            "How to Track Quora Traffic",
            "Closures",
          ],
        ],
      },
    ],
    "Tab 2": [
      {
        id: "flush-collapseOne",
        title: "Content Marketing",
        content: [
          [
            "Introduction",
            "The Power of Storytelling",
            "Building a Content Framework",
            "Generating Content Ideas",
            "Copywriting",
          ],
          [
            "How to Write a Great Content",
            "Content Marketing Tools",
            "Analyzing & Measuring Your Results",
            "Closing",
          ],
        ],
      },
      {
        id: "flush-collapseTwo",
        title: "Google Adsense Course",
        content: [
          [
            "Introduction",
            "Sign Up for a Google Ads Account",
            "Link Google Ads with Your WordPress Website",
            "Place Auto Ads on Your Website to Maximize Revenue",
            "Install Google Site Kit",
            "Fix Your GDPR and CCPA Cookie Message",
            "Create a Search Engine Ad",
            "Check Out the AdSense Report",
            "Add a Second Website to Your Google AdSense Account",
          ],
          [
            "Craft Privacy Policy",
            "Submit Your Ads.txt File to Google",
            "Configure Your Auto Ads",
            "Create an Ad Unit within Google AdSense",
            "Use Advanced Ads in WordPress to Place Ads All Over Your Website",
            "Link Your AdSense Account with Your YouTube Channel",
            "How to Get and Fill in Your Google Pin to Receive Your Money",
            "Optimize Your Ads for Better Conversion",
            "When Do You Get Paid with Google AdSense?",
            "Closing",
          ],
        ],
      },
      {
        id: "flush-collapseThree",
        title: "Affiliate Marketing",
        content: [
          [
            "Introduction to Affiliate Marketing",
            "Finding and Joining Affiliate Programs",
            "Creating Content and Promoting Products",
            "Tracking and Analyzing Results",
          ],
          [
            "Monetizing Your Blog or Website",
            "Building Your Brand and Reputation",
            "Scaling Your Affiliate Marketing Business",
            "Conclusion",
          ],
        ],
      },
      {
        id: "flush-collapseFour",
        title: "Google Analytics",
        content: [
          [
            "Why Use Analytics?",
            "Tools for SEO Analytics",
            "Installing Analytics Code on Site",
            "Understanding Audience, Traffic, and Site Reports",
            "Importance of Goals and Conversions",
          ],
          [
            "Installing the Conversion Tracking Code",
            "Tracking Conversions",
            "Analyzing Conversions",
            "Generating Reports",
            "Closing",
          ],
        ],
      },
      {
        id: "flush-collapseFive",
        title: "Web Master Tools",
        content: [
          [
            "What is GWM?",
            "Adding Sites and Verification",
            "Setting Geo Target Locations",
            "Search Query Analysis",
            "Filtering Search Queries",
            "External Links Report",
            "Crawl Stats and Errors",
          ],
          [
            "Google Fetch",
            "XML Sitemaps",
            "Robots.txt",
            "Importance of Disavow Links Tool",
            "HTML Suggestions",
            "Content Keywords",
          ],
        ],
      },
      {
        id: "flush-collapseSix",
        title: "Email Marketing",
        content: [
          [
            "Getting Started with Email Marketing",
            "Email Marketing Service Providers",
            "Email Marketing Tools List",
            "Setting Up Email Campaigns",
            "Conclusion",
          ],
        ],
      },
      {
        id: "flush-collapseSeven",
        title: "Local SEO (GMD)",
        content: [
          [
            "What is Local SEO?",
            "Importance of Local SEO",
            "How to Check if Your Business is Already Listed",
            "Importance of Google Guidelines for Maps",
            "Creating a Google My Business Listing",
          ],
          [
            "Google My Business Ranking Factors",
            "GMB Dashboard: Insights, Photos, Categories, Reviews, Posts, Q&A, User Feedback",
            "GMB Adding Multiple Locations",
          ],
        ],
      },
      {
        id: "flush-collapseEight",
        title: "WhatsApp Marketing",
        content: [
          [
            "Introduction to WhatsApp Marketing",
            "WhatsApp Personal",
            "WhatsApp Business",
            "Importance of WhatsApp Business",
            "Installing WhatsApp Business App",
            "Optimizing WhatsApp Business App",
          ],
          [
            "Secrets to Send Bulk Messages",
            "WhatsApp Automation Scenarios",
            "Working with WhatsApp Automation Tools",
            "Campaign Setup, Optimization",
            "Measuring WhatsApp Ads",
          ],
        ],
      },
      {
        id: "flush-collapseNine",
        title: "How To Start Freelancing",
        content: [
          [
            "Understanding Digital Marketing",
            "Identifying Your Niche and Target Market",
            "Building Your Freelance Brand",
            "Creating an Online Presence",
            "Developing Marketing Skills",
          ],
          [
            "Networking and Finding Clients",
            "Setting Your Rates and Negotiating Deals",
            "Creating a Portfolio and Presenting Your Skills",
            "Staying Current with Industry Trends and Updates",
            "Managing Projects and Client Expectations",
          ],
        ],
      },
      {
        id: "flush-collapseTen",
        title: "How to Prepare a Resume",
        content: [
          [
            "Introduction",
            "Digital Marketing Job Survey",
            "Research & Analytics",
            "SEO Your Resume",
          ],
          [
            "Free Digital Marketing Resume Templates",
            "Resume Submission & Applying for Jobs",
            "Job Portals",
          ],
        ],
      },
    ],
  };
  const features = [
    {
      id: 1,
      icon: eligibilityIcon,
      title: "Eligibility",
      description: "Suitable for All Graduates",
      extra: "No Coding Background Required",
    },
    {
      id: 2,
      icon: courseDurationIcon,
      title: "Course Duration",
      description: "6 Months to Complete, Provided the Schedule is Followed",
    },
    {
      id: 3,
      icon: intershipIcon,
      title: "INTERNSHIP PROGRAM",
      description:
        "Work on live projects and gain practical insights into industry practices",
    },
    {
      id: 4,
      icon: certificateIcon,
      title: "CERTIFICATE",
      description: "Certification Ensures You Are Ready for Industry Roles",
    },
  ];

  const reviewsData = [
    {
      degree: "Non-Engineering Degree",
      reviewText:
        "I registered in TechU , my first step toward a job, with my husband's help. Here, I started from scratch and developed solid tech foundations. I had no experience with marketing when I first started, but today I can easily develop straight forward methods to solve complicated issues. Along with that, I aced my interview thanks to the placement help staff.",
      studentImg: testmonialimg1,
      studentName: "Krishnaveni",
      designation: "Data Analyst",
      package: "6 Lpa",
      location: "Hyderabad",
    },
    {
      degree: "Non-Engineering Degree",
      reviewText:
        "I moved from a tiny support function to the digital marketing. I enrolled in TechU to get  skills because I have always been interested in marketing. I improved my skill set and honed my marketing abilities here. I was able to land a well-paying position with the placement support team's unwavering help.",
      studentImg: testmonialimg3,
      studentName: "Srikar",
      designation: "Email Marketing",
      package: "8.5 Lpa",
      location: "Hyderabad",
    },
    {
      degree: "Non-Engineering Degree",
      reviewText:
        "This program has been a game-changer for my career in digital marketing. Although I took extra time in college to clear my backlogs, the program's methodologies and practical learning opportunities were incredibly beneficial.",
      studentImg: testmonialimg2,
      studentName: "Sushma",
      designation: "Digital Marketing",
      package: "13 Lpa",
      location: "Hyderabad",
    },
    {
      degree: " Non IT Branch to IT Job",
      reviewText:
        "After receiving my degree in EEE, I spent a month working in my primary field. The epidemic then struck. It was then that I learned about TechU, which completely changed my life. The straight forward explanations provided enabled me to grasp the difficult ideas. I received the much-needed motivation from mindset training until I was hired.",
      studentImg: testmonialimg4,
      studentName: "Rubi",
      designation: "Social Media Marketing",
      package: "6 Lpa",
      location: " Hyderabad",
    },
    {
      degree: " Non IT Branch to IT Job",
      reviewText:
        "I joined TechU  to acquire skills that are ready for the workforce after my career gap prevented me from finding employment. All of the information in TechU is structured. I picked up digital marketing  because to this place's trainers and instructional techniques.",
      studentImg: testmonialimg5,
      studentName: "Mahendra.N",
      designation: "Digital Analyst",
      package: "10 Lpa",
      location: " Hyderabad",
    },
    {
      degree: "Got Into Top MNCs",
      reviewText:
        " At TechU, the team provided me with comprehensive support. Learning in my native language made the process much easier. I felt confident that I would secure a job. If you are determined to learn and advance in your career, TechU is the perfect place for you.",
      studentImg: testmonialimg6,
      studentName: " Snehitha",
      designation: "Content Marketing",
      package: "8 Lpa",
      location: "Hyderabad",
    },
  ];

  const keyHighlights = [
    {
      img: livesections,
      title: "Live Sessions Available Monday through Friday",
      text: "To understand coding best practices, receive interview advice, address queries, and more from experts.",
    },
    {
      img: oneOnOne,
      title: "One-on-One Success Coaching for Each Student",
      text: "To assist you and make sure your learning journey is smooth",
    },
    {
      img: plecement,
      title: "Placement Success Coordinator",
      text: "To provide feedback on your interview performance and enhance your confidence.",
    },
    {
      img: softskills,
      title: "Soft Skills Sessions at TechU",
      text: "Our Soft Skills Sessions empower you with essential interpersonal skills",
    },
    {
      img: mockinterviews,
      title: "Mock Interviews",
      text: "Conduct mock interviews that mimic real-life scenarios and get immediate, specific feedback",
    },
    {
      img: Masterclasses,
      title: "Masterclasses with Top Industry Professionals",
      text: "To provide you with the latest trends and keep you aligned with industry developments.",
    },
    {
      img: merntoring,
      title: "Support from Successful Senior Mentors",
      text: "Alumni from over 1,700 companies to perfect your placement preparation.",
    },
  ];

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
    }

    if (!formData.mobile.trim()) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      formErrors.mobile = "Mobile number must be 10 digits";
    }

    if (!formData.nativeState.trim()) {
      formErrors.nativeState = "State is required";
    }

    if (!formData.education) {
      formErrors.education = "Please select a qualification";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const res = await axios.post(
          "https://server.techu.in/Demoregister",
          formData
        );
        if (res.status === 201) {
          setFormData({
            name: "",
            mobile: "",
            nativeState: "",
            education: "",

          });
          navigate("/thankyou");
          window.location.reload();
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          setErrorMessage("Cannot register with this mobile number");
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const qualifications = [
    { value: "", label: "Select Highest Qualification" },
    { value: "highschool", label: "High School" },
    { value: "bachelor", label: "Bachelor's Degree" },
    { value: "master", label: "Master's Degree" },
    { value: "doctorate", label: "Doctorate" },
  ];

  const nativeStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const whatsappNumber = "9154747782";
  const whatsappMessage =
    "Hello, I would like to know more about your Digital Marketing course.";
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    whatsappMessage
  )}`;

  const handleWhatsAppClick = () => {
    window.open(whatsappLink, "_blank");
  };

  return (
    <div>
      {/* hero section */}
      <div>
        <div className="#hero-section" id="hero-section">
          <div className="contactus-top-section">
            <div className="contatus-us-container">
              <div className="">
                <p className="contatus-number-text pt-2">
                  {" "}
                  <FiPhone size={20} className="me-2" /> Call Now: +91{" "}
                  <a
                    href="tel:+919154747782"
                    target="_blank"
                    className="phone-numbers"
                  >
                    9154747782
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="hero-section-container">
            <div className="hero-main-container">
              <div className="hero-section-sub-container-2">
                <div className="hero-section-sub-left">
                  <div className="hero-heading-div">
                    {/* <p className="hero-heding-text">
                      India's #1 Award Winning Institute
                    </p> */}
                    <h1 className="hero-heading-1">
                      <span className="hero-heading-course-name"> DIGITAL MARKETING</span> Course in Hyderabad with Job Placement Support
                    </h1>
                  </div>
                  <div>
                    <p className="hero-section-content">
                      "Don't let lockdown hold you back! Join{" "}
                      <span className="heading-span-text">
                        Techu's practical and job-oriented program{" "}
                      </span>
                      , available online and in-person. Enhance your digital
                      skills at affordable rates today!.
                    </p>
                  </div>
                  <div className="hero-keypoints-const">
                    <div className="hero-keypoint-1">
                      <div className="hero-key-sub">
                        <FaRegCheckCircle
                          size={20}
                          className="circle-check-icon"
                        />
                        <span className="hero-keypoints-text">
                          Online Digital Marketing Course
                        </span>
                      </div>
                      <div className="hero-key-sub">
                        <FaRegCheckCircle
                          size={20}
                          className="circle-check-icon"
                        />
                        <span className="hero-keypoints-text">
                          Search Engine Optimization Course
                        </span>
                      </div>
                    </div>
                    <div className="hero-keypoint-1">
                      <div className="hero-key-sub">
                        <FaRegCheckCircle
                          size={20}
                          className="circle-check-icon"
                        />
                        <span className="hero-keypoints-text">
                          Classroom Digital Marketing Course
                        </span>
                      </div>
                      <div className="hero-key-sub">
                        <FaRegCheckCircle
                          size={20}
                          className="circle-check-icon"
                        />
                        <span className="hero-keypoints-text">
                          Website Designing Course
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="ratiing-container">
                    <span className="hero-section-ratings">
                      Rating 4.9/5 By 1000+ Students
                    </span>
                    <FaStar size={20} className="rating-stars" />{" "}
                    <FaStar size={20} className="rating-stars" />{" "}
                    <FaStar size={20} className="rating-stars" />{" "}
                    <FaStar size={20} className="rating-stars" />
                  </div>
                  <div className="download-class">
                    <button
                      className="download-syllabus-button"
                      onClick={handleClick}
                    >
                      <FaDownload size={20} style={{ color: "#FFFF" }} />{" "}
                      DOWNLOAD SYLLABUS
                    </button>
                  </div>
                  <div className="download-class">
                    <p className="hero-keypoints-text certifue-gdt">
                      Certification In Alignment With
                    </p>
                    <div className="d-flex gap-2 mt-2">
                      <img
                        alt=""
                        src={googleImg}
                        className="certification-img-1"
                      />
                      <img
                        alt=""
                        src={hostpostImg}
                        className="certification-img-2"
                      />
                      <img
                        alt=""
                        src={facebookImg}
                        className="certification-img-1    "
                      />
                      <img
                        alt=""
                        src={youtubeImg}
                        className="certification-img-1"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="hero-section-sub-rigth">
                    <div className="hero-section-right-container">
                      <div id="form" className="form-container">
                        <div className="form-container-sub-1">
                          <div className="text-under-line">
                            <div>
                              Book a Live Demo <br /> For Free
                            </div>
                          </div>
                          <div className="form-student-img w-embed">
                            <img
                              src={formStudent}
                              alt=""
                              loading="eager"
                              className="formstudent-img"
                            />
                          </div>
                        </div>
                        <div
                          id="book-a-free-demo"
                          className="scholarship-form-techu"
                        >
                          <form className="form" onSubmit={handleSubmit}>
                            <fieldset className="fieldset">
                              <div className="form-group mb-1">
                                <label className="form-label">Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Enter Your Name"
                                  className="form-control"
                                  value={formData.name}
                                  onChange={handleChange}
                                  ref={targetFieldRef}
                                />
                                {errors.name && (
                                  <span className="error">{errors.name}</span>
                                )}
                              </div>
                              <div className="form-group mb-1">
                                <label className="form-label">
                                  Mobile Number (WhatsApp Number)
                                </label>
                                <div className="mobile-input-container form-control">
                                  <select
                                    id="countryCode"
                                    name="countryCode"
                                    className="selisted-li-list-in me-1"
                                    value={formData.countryCode}
                                    onChange={handleChange}
                                  >
                                    <option value="">IN</option>
                                  </select>
                                  <input
                                    type="number"
                                    id="mobileNumber"
                                    name="mobile"
                                    placeholder="Enter Mobile Number"
                                    className="mobile-input ms-4"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                  />
                                  <span className="mobilenumber-1">+91</span>
                                </div>
                                {errors.mobile && (
                                  <span className="error">{errors.mobile}</span>
                                )}
                                <p className="form-check-label">
                                  We assure you that we practice a strict
                                  'NO-SPAM' policy
                                </p>
                              </div>
                              <div className="form-group mb-1">
                                <label className="form-label">
                                  Highest Qualification
                                </label>
                                <select
                                  name="education"
                                  className="form-control selectedItem-name"
                                  value={formData.education}
                                  onChange={handleChange}
                                >
                                  {qualifications.map((option, index) => (
                                    <option
                                      key={index}
                                      value={option.value}
                                      className="selisted-li-list"
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                {errors.education && (
                                  <span className="error">
                                    {errors.education}
                                  </span>
                                )}
                              </div>
                              <div className="form-group mb-1">
                                <label className="form-label">
                                  Native State
                                </label>
                                <select
                                  name="nativeState"
                                  className="form-control"
                                  value={formData.nativeState}
                                  onChange={handleChange}
                                >
                                  <option value="">Select your State</option>
                                  {nativeStates.map((state, index) => (
                                    <option key={index} value={state}>
                                      {state}
                                    </option>
                                  ))}
                                </select>
                                {errors.nativeState && (
                                  <span className="error">
                                    {errors.nativeState}
                                  </span>
                                )}
                              </div>
                              <div className="form-group mb-1">
                                <div className=" form-check-1">
                                  <input
                                    type="checkbox"
                                    name="receiveUpdates"
                                    className="form-check-input"
                                    checked={formData.receiveUpdates}
                                    onChange={handleChange}
                                  />
                                  <div className="form-check-label-c">
                                    <FaWhatsapp
                                      style={{
                                        color: "#0ed10e",
                                        fontSize: "27px",
                                        marginTop: "5px",
                                      }}
                                    />
                                    <label className="form-check-label">
                                      I want to receive updates directly on
                                      WhatsApp
                                    </label>
                                  </div>
                                </div>

                                {errors.receiveUpdates && (
                                  <span className="error">
                                    {errors.receiveUpdates}
                                  </span>
                                )}
                              </div>

                              <div className="form-group text-center-content-tfd">
                                <div className="form-techu-hh">
                                  <span className="form-spam-hres form-check-label">
                                    By proceeding further, I agree to the{" "}
                                    <a
                                      href="https://www.techu.in/#/terms"
                                      className="form-links"
                                    >
                                      Terms & Conditions
                                    </a>{" "}
                                    and{" "}
                                    <a
                                      href="https://www.techu.in/#/privacy"
                                      className="form-links"
                                    >
                                      Privacy Policy
                                    </a>{" "}
                                    of TechU
                                  </span>
                                </div>
                              </div>
                              {errorMessage && (
                                <h6 style={{ color: "red" }}>{errorMessage}</h6>
                              )}
                              <div className="form-button-demo-t">
                                <button
                                  type="submit"
                                  className="button-demosection"
                                >
                                  <div className="book-demo-button-text">
                                    Submit
                                  </div>
                                </button>
                              </div>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* chat with us container */}
      <div
        id="chat-with-us-div"
        className="whatsappdiv-container"
        onClick={handleWhatsAppClick}
        style={{ cursor: "pointer" }}
      >
        <div
          id=""
          href=""
          className="whatsappdiv-block whatup-home  whatsapp-us t-inline-block-whatup"
        >
          <div className="whatup-text">Chat with us</div>
          <div className="whapup-div">
            <div className="whatup-img">
              <FaWhatsapp
                style={{
                  color: "#ffff",
                  fontSize: "35px",
                  marginTop: "5px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* featured */}
      <div className="">
        {" "}
        <div id="featured-container" className="featured-container">
          <div id="feature-desktop" className="featured-main-container">
            <div className="featured-div-grid program-features-grid">
              {features.map((feature, index) => (
                <div key={index} className="program-features">
                  <div>
                    <div className="featured-image">
                      <img
                        src={feature.icon}
                        alt=""
                        loading="lazy"
                        className="featured-image-img"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="featured-course-text">{feature.title}</div>
                    <div className="featured-course-description">
                      {feature.description}
                      {feature.extra && (
                        <span className="text-span-"> {feature.extra}</span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Trainer */}
      <section className="about-trainer-main">
        <div className="about-trainer-section">
          <div className="trainer-section-left pt-5">
            <h1 className="trainer-head hero-heading-course-name">Know Your Trainer</h1>
            <p className="trainer-para"  >
              Yoganand, with 12 years of experience in Digital Marketing, has trained over 15,500+ students.</p>
            <ul className="trainer-ul-tags ">
              <li><strong className="heading-span-text">12 years of practical experience</strong> in Digital Marketing, making complex strategies accessible.</li>
              <li><strong className="heading-span-text">Guided and supported over 15,500+ professionals</strong> as a marketer, trainer, and consultant, helping them master digital campaigns.</li>
              <li><strong className="heading-span-text">Certified Digital Marketing Professional</strong>, passionate about delivering actionable insights and creative solutions.</li>
            </ul>
            <div className="buttons-container trainer-btn-div ">
          <button
            id=""
            href="#hero-section"
            onClick={() => handleclick("hero-section")}
            className="book-start-a-demo trainer-btn"
          >
            Book a Free Demo
          </button>
        </div>
          </div>
          <div className="trainer-section-right">
            <img src={trainer} className="trainer-img" />
          </div>
        </div>
       
      </section>

      {/* Testimonials */}
      <section id="Testimonials" className="reviews-slider">
        <div className="reviews-slider-container">
          <div className="reviews-heading-div">
            <h2 className="reviews-heading">
              Your Seniors Were Placed. Now it's your turn!
            </h2>
          </div>

          <div className="reviews-map-container">
            {reviewsData.map((review, index) => (
              <div
                className="reviews-slider-card-container reviews-slider-1"
                key={index}
              >
                <div className="reviews-slider-caard">
                  <div className="reviews-slider-card-content">
                    <div className="reviews-slider-img-container">
                      <div className="reviews-slider-img w-embed">
                        <img
                          src={review.studentImg}
                          loading="lazy"
                          alt=""
                          className="reviws-student-img"
                        />
                      </div>
                      <div className="reviews-slider-img-content">
                        <div className="reviews-slider-card-student-name">
                          {review.studentName}
                        </div>
                        <div className="reviews-slider-card-designation">
                          {review.designation}
                        </div>
                        {/* <div className="reviews-slider-card-pakage">
                          {review.package}
                        </div> */}
                        <div className="reviews-slider-card-native-place">
                          {review.location}
                        </div>
                      </div>
                    </div>
                    <div className="reviews-slider-text-para">
                      {review.reviewText}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="book-demo-container">
          <div className="">
            <div className="">
              <div className="">
                <div className="book-demo-reviews-buttons batch-dates-buttons">
                  <div className="buttons-container">
                    <button
                      id=""
                      // href="#hero-section"
                      onClick={() => handleclick("hero-section")}
                      className="book-start-a-demo "
                    >
                      Book a Free Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* course overviews */}
      <div className="">
        <section id="overview" className="course-overview-container">
          <div className="course-overview-main-container">
            <div className="course-overview-div-block">
              <h3 className="course-overview-heading">
                Explore the Digital Marketing Course in Hyderabad.
              </h3>
            </div>
            <div className="course-overview-text-block">
              ou can elevate your career with our Digital Marketing course in
              Hyderabad, where you'll learn the fundamentals of modern
              marketing. This training will equip you with industry-relevant
              skills, and with dedicated placement assistance, you'll be on the
              path to a successful career in no time.
            </div>
            <div className="course-overview-key-heighlights">
              <div className="key-heighlights-text-block">Key Highlights</div>
              <div className="key-heighlights-div-block-1">
                {keyHighlights.slice(0, 4).map((highlight, index) => (
                  <div key={index} className="key-heighlights-div-block-2">
                    <img
                      loading="lazy"
                      src={highlight.img}
                      alt=""
                      className="key-heighlights-image"
                    />
                    <div className="div-block-content-main">
                      <div className="text-block-text">{highlight.title}</div>
                      <div className="text-block-text-1">{highlight.text}</div>
                    </div>
                  </div>
                ))}
                {showAll &&
                  keyHighlights.slice(4).map((highlight, index) => (
                    <div key={index} className="key-heighlights-div-block-2">
                      <img
                        loading="lazy"
                        src={highlight.img}
                        alt=""
                        className="key-heighlights-image"
                      />
                      <div className="div-block-content-main">
                        <div className="text-block-text">{highlight.title}</div>
                        <div className="text-block-text-1">
                          {highlight.text}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className="course-show-hide-container"
                onClick={handleToggle}
              >
                <div className="show-allcourse-updated">
                  {showAll ? "Hide all Upgrades" : "Show all Upgrades"}
                </div>
                <div className={`transform-example ${showAll ? "active" : ""}`}>
                  <FaChevronDown
                    size={20}
                    className="show-more-icon ms-2"
                    style={{ color: "#C76A97" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      {/* course curriculum */}
      <section id="syllabus" className="curriculum-main-container">
        <div className="curriculum-container">
          <article>
            <h2 className="curriculum-heading">
              Digital Marketing Course Syllabus
            </h2>
            <p className="fullstack-curriculum-content">
              Our course in Digital Marketing consists of modules that encompass
              the following Topics.
            </p>
            <div className="curriculum-tabs">
              <div className="curriculum-tabs-menu t-tab-menu">
                {tabsData.map((tab) => (
                  <button
                    key={tab.id}
                    className={`curriculum-tab-${tab.id.replace(
                      "Tab ",
                      ""
                    )} track-button t-inline-block t-tab-link ${activeTab === tab.id ? "t--current" : ""
                      }`}
                    onClick={() => handleTabChange(tab.id)}
                  >
                    <div>{tab.label}</div>
                  </button>
                ))}
              </div>
              <div className="curriculum-tabs-content-2">
                {accordionData[activeTab] && (
                  <div className="t-tab-pane t--tab-active">
                    <div className="ciurriculum-main">
                      <div className="div-block-curriculum-child">
                        <div className="curriculum-child">
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            {accordionData[activeTab]?.map((item) => (
                              <div
                                className="accordion-item mt-4"
                                key={item.id}
                              >
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#${item.id}`}
                                    aria-expanded="false"
                                    aria-controls={item.id}
                                  >
                                    {item.title}
                                  </button>
                                </h2>
                                <div
                                  id={item.id}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        {item?.content?.map((list, index) => (
                                          <ul
                                            className="body-rounded-unorder-list"
                                            key={index}
                                          >
                                            {list.map((listItem, i) => (
                                              <li key={i}>{listItem}</li>
                                            ))}
                                          </ul>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div role="list" className="">
                <div role="listitem" className="">
                  <div className="demo-button-collection-container">
                    <div className="bact-start-button-container">
                      <button
                        id="book-a-free-demo-course-curriculum"
                        onClick={() => handleclick("hero-section")}
                        className="book-start-a-demo"
                      >
                        Book a Free Demo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </div>
  );
};

export default TechuHome;
