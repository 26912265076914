import React from "react";
import "./techufooter.css";
import { BiMap } from "react-icons/bi";
import { BsClock } from "react-icons/bs";
// import { FaPhone } from "react-icons/fa6";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
// import { SiGmail } from "react-icons/si";
import { MdEmail } from "react-icons/md";

const TechUFooter = ({ handleclick }) => {
  return (
    <>
      <div className="register-div">
        <h1 className="register-for-demo">REQUEST FOR DEMO LECTURE</h1>
        <div className="request-for-demo-btn" onClick={() => handleclick("hero-section")} style={{ cursor: "pointer" }}>
          <a
            className="register-btn" >
            REGISTER FOR DEMO
          </a>
        </div>
      </div>
      <div className="city-container">
        <h5 className="city-containe-title">
          <strong>
            Check Out Our Digital Marketing Online Course According To Your
            City:
          </strong>
        </h5>
        <p className="city-links-div">
          <a className="city-links" href="#">
            Mumbai
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Bangalore
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Ahmedabad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Chennai
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Kolkata
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Surat
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Pune
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Hyderabad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Jaipur
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Lucknow
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Kanpur
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Nagpur
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Indore
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Thane
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Bhopal
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Visakhapatnam
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Pimpri Chinchwad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Patna
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Vadodara
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Ghaziabad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Ludhiana
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Agra
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Nashik
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Ranchi
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Faridabad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Meerut
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Rajkot
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Kalyan
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Dombivli
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Vasai
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Virar
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Varanasi
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Srinagar
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Aurangabad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Dhanbad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Gurgaon
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Amritsar
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Navi Mumbai
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Allahabad
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Howrah
          </a>
          {"  "}|{" "}
          <a className="city-links" href="#">
            Gwalior
          </a>
        </p>
      </div>
      <div className="digital-marketing-footer-main">
        <div className="digital-marketing-footer-inner ">
          <div className="footer-left">
            <div className="footer-1">
              <h1 className="footer-heading">
                Head Office: Madhapur, Hyderabad
              </h1>
              <p className="footer-para">
                TechU - Digital Marketing Courses In Hyderabad, Training
                Institute
              </p>
              <div className="footer-left-ul-elements-div">
                <ul className="footer-left-ul-elements">
                  <li>
                    <BiMap icon={BiMap} size={20} className="footer-icon-map" />
                    <span className="footer-li-span">
                      Address:TechU, #303B Images Capital Park, Madhapur,
                      Hyderabad - 500081
                    </span>
                  </li>
                  <li>
                    <BsClock
                      icon={BsClock}
                      size={20}
                      className="footer-icon-clock"
                    />
                    <span className="footer-li-span">
                      Mon To Fri 9:30 AM – 06:30 PM Visiting Hours / Enquiry
                    </span>
                  </li>
                  <li>
                    <a
                      href="mailto:techutraining@gmail.com"
                      className="em-btn tbtn inline-div "
                    >
                      <div className="footer-email">
                        <div className="em-icon ">
                          <MdEmail className="footer-icon-clock" />
                        </div>
                        <div className="wa-link">techutraining@gmail.com</div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="block-div-2">
                      <a
                        href="tel:+919154747782"
                        target="_blank"
                        className="wa-btn tbtn inline-div"
                      >
                        <div className="wa-icon">
                          <FaWhatsapp
                            style={{
                              color: "#ffffff",
                              background:
                                "linear-gradient(#23b33a, #23b33a, #23b33a)",
                              borderRadius: "50%",
                              width: "25px",
                              height: "25px",
                              padding: "0px",
                            }}
                          />
                        </div>
                        <div>
                          <div className="wa-link">
                            +91 9154747782 {"  "}(WhatsApp only)
                          </div>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2656437335995!2d78.3836042743742!3d17.446995183450333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf75a6036f38d0d1%3A0x5136a7418cf52179!2sTechU%20by%20Abilio!5e0!3m2!1sen!2sin!4v1723199583467!5m2!1sen!2sin" className="gmap" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-3">
              <ul>
                <h1 className="footer-heading-links-ul">Quick Links:</h1>
                <li className="footer-heading-links-li">
                  <a href="#">Online Digital Marketing Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">Classroom Digital Marketing Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">SEO Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">Graphic Designing Course</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#">Masters Digital Marketing Course</a>
                </li>
              </ul>
            </div>
            <div className="footer-3">
              <ul>
                <h1 className="footer-heading-links-ul">Useful Links:</h1>
                <li className="footer-heading-links-li">
                  <a href="#Testimonials"> Our Placement</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="#Testimonials">Students Feedback</a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="https://techu.in/" target="_blank">
                    About Us
                  </a>
                </li>
                <li className="footer-heading-links-li">
                  <a href="https://techu.in/#/privacy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
              <div className="footer-logos">
                <a
                  href="https://www.facebook.com/techubyabilio"
                  target="_blank"
                >
                  <FaFacebook
                    style={{
                      color: "#ffffff",
                      background: "#4267B2",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      padding: "0px",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/techu_by_abilio/"
                  target="_blank"
                >
                  <FaInstagram
                    style={{
                      color: "#ffffff",
                      background:
                        "linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      padding: "5px",
                    }}
                  />
                </a>

                <a
                  href="https://www.youtube.com/@Tech_U820/featured"
                  target="_blank"
                >
                  <IoLogoYoutube
                    style={{
                      color: "#FF0000",
                      background: "#ffffff",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      padding: "5px",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/techu-by-abilio-a105b731b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank"
                >
                  <FaLinkedin
                    size={25}
                    style={{
                      color: "#ffffff",
                      // background: "#ffffff",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechUFooter;
