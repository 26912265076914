import React, { useRef, useState, useEffect } from "react";
import "./techucompany.css";
import companyimageone from "./images/company-image1.png";
import companyimagetwo from "./images/company-image2.png";
import companyimagethree from "./images/company-image3.png";
import googleAds from "./images/googleAds.png";
import buffer from "./images/buffer.png";
import canva from "./images/canva.png";
import copyai from "./images/copyai.png";
import gkp from "./images/gkp.png";
import googleanalytics from "./images/googleanalytics.png";
import gsc from "./images/gsc.png";
import mailchimp from "./images/mailchimp.png";
import linkedinads from "./images/linkedinads.png";
import semrush from "./images/semrush.png";
import moz from "./images/moz.png";
import seoptimer from "./images/seoptimer.png";
import wordpress from "./images/wordpress.png";
import youtubestudio from "./images/youtubestudio.png";
import hubspotcertificate from "./images/hubspot-certificate.png";
import jobskill from "./images/Job-sec-image.png";
import certificate from "./images/course-certificate.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { MdOutlineDone } from "react-icons/md";
import "swiper/css";
import "swiper/css/navigation";
import facebookImg from "./images/facebook-logo-img.png"

import youtubeImg from "./images/youtube-logo.png"
import googleImg from "./images/google-img-certi.png"
const TechuCompany = ({ handleclick }) => {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = screenWidth <= 767;
  const ipad = screenWidth >= 768 && screenWidth <= 1219;

  // Initialize Swiper
  useEffect(() => {
    const swiper = swiperRef.current?.swiper;
    const handleSlideChange = () => {
      if (swiper) {
        const newIndex = swiper.realIndex; // Use realIndex to account for looping
        setActiveIndex(newIndex);
      }
    };

    if (swiper) {
      swiper.on("slideChange", handleSlideChange);
      // Initialize active index
      handleSlideChange();
    }

    return () => {
      if (swiper) {
        swiper.off("slideChange", handleSlideChange);
      }
    };
  }, [swiperRef]);

  // Handle slide navigation
  const goToSlide = (index) => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  const slidePrev = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const slideNext = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };

  const renderPaginationBars = () => {
    const swiper = swiperRef.current?.swiper;
    if (!swiper) return null;

    // Determine slidesPerGroup based on device type
    const slidesPerGroup = isMobile ? 1 : ipad ? 2 : 3;
    const totalSlides = swiper.slides.length;
    const numGroups = Math.ceil(totalSlides / slidesPerGroup);
    const currentGroupIndex = Math.floor(activeIndex / slidesPerGroup);

    return Array.from({ length: numGroups }, (_, index) => (
      <div
        key={index}
        onClick={() => goToSlide(index * slidesPerGroup)}
        className={`progressbar-nav-item ${index === currentGroupIndex ? "active" : ""
          }`}
      />
    ));
  };
  const projects = [
    {
      "title": "Project 1",
      "heading": "Predictive Maintenance",
      "description": "Build a predictive maintenance system using machine learning to analyze sensor data. Use Python and Scikit-learn, store data in SQL, and deploy on AWS or Google Cloud."
    },
    {
      "title": "Project 2",
      "heading": "Real-Time Data Analytics",
      "description": "Implement real-time data streaming with Apache Kafka and Spark. Use Python or Scala, store data in MongoDB, and deploy on a cloud platform."
    },
    {
      "title": "Project 3",
      "heading": "Image Classification",
      "description": "Create an image classification model using CNNs with TensorFlow or PyTorch. Use Jupyter Notebook or PyCharm for development and cloud platforms for training."
    },
    {
      "title": "Project 4",
      "heading": "Customer Churn Prediction",
      "description": "Develop a churn prediction model using machine learning. Use Python, store data in PostgreSQL, and leverage cloud platforms for computing."
    },
    {
      "title": "Project 5",
      "heading": "Twitter Sentiment Analysis",
      "description": "Perform sentiment analysis on Twitter data using NLP techniques with Python. Use NLTK or SpaCy and store results in Amazon RDS."
    },
    {
      "title": "Project 6",
      "heading": "E-Commerce Recommendation Engine",
      "description": "Build a recommendation engine using collaborative filtering. Implement with Python, store data in Cassandra, and deploy on a cloud platform."
    }
  ];
  return (
    <>
      {/* Hiring companies */}
      <section id="Hiring-companies-section" className="hiring-companies-sec">
        <div className="companies-hired">
          <h2 className="companies-hired-top-div">
            TOP IT COMPANIES HIRE
            <span className="companies-hired-top-div-txt-span-01"> TECHU </span>
            LEARNERS!
          </h2>
          <div className="companies-hired-images">
            <div className="scroll-container">
              <div className="scroll-shadow"></div>
              <div className="scroll-left ">
                <div className="scroll-images">
                  <img
                    className="company-images"
                    loading="lazy"
                    src={companyimageone}
                    alt="Company Image One"
                  />
                </div>
              </div>
              <div className="scroll-right w-100 my-4">
                <div className="scroll-images">
                  <img
                    loading="lazy"
                    src={companyimagetwo}
                    alt="Company Image One"
                  />
                </div>
              </div>
              <div className="scroll-left w-100 my-4">
                <div className="scroll-images">
                  <img
                    loading="lazy"
                    src={companyimagethree}
                    alt="Company Image One"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* tools section */}
      <section id="tools-section" className="tools-sec">
        <div className="tools-section-top-div">
          <h2 className="tools-section-heading">
            TOOLS YOU WILL MASTER IN DIGITAL MARKETING
          </h2>
          <div className="tools-section-bottom-div">
            <div className="tools-images">
              <img
                className="tools-image-individual"
                loading="lazy"
                src={googleAds}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={canva}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={youtubestudio}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={wordpress}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={seoptimer}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={moz}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={semrush}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={linkedinads}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={mailchimp}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={gsc}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={googleanalytics}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={gkp}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={copyai}
                alt="googleAds"
              />
            </div>
            <div>
              <img
                className="tools-image-individual"
                loading="lazy"
                src={buffer}
                alt="googleAds"
              />
            </div>
          </div>
        </div>
      </section>
      {/* projects */}
      <section className="project-course">
        <article className="projects-course-top">
          <div>
            <div className="projects-course-top-heading">
              Projects You'll Develop in this Digital Marketing + Generative AI
            </div>
            <div className="projects-course-top-para">
              Strengthen Your Portfolio by Working on Real-World Projects.
            </div>
          </div>

          <div className="swiper-wrapper-container">
            <Swiper
              ref={swiperRef}
              modules={[Navigation]}
              slidesPerView={isMobile ? 1 : ipad ? 2 : 3}
              spaceBetween={10}
              slidesPerGroup={isMobile ? 1 : ipad ? 2 : 3}
              onSlideChange={() => {
                const swiper = swiperRef.current?.swiper;
                if (swiper) {
                  const newIndex = swiper.realIndex;
                  setActiveIndex(newIndex);
                }
              }}
              navigation={{
                nextEl: ".right-nav-swiper-button",
                prevEl: ".left-nav-swiper-button",
              }}
              className="swiper-container"
              loop={true}
            >
              {projects.map((project, index) => (
                <SwiperSlide key={index}>
                  <div className="project-course-project-01">
                    <div
                      className={`project-course-project-0${index + 1}-title`}
                    >
                      {project.title}
                    </div>
                    <div className="project-course-project-01-heading">
                      {project.heading}
                    </div>
                    <div className="project-course-project-01-para">
                      {project.description}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-nav-btns d-flex justify-content-center">
              <button className="left-nav-swiper-button" onClick={slidePrev}>
                <ChevronLeft size={25} />
              </button>
              <div className="custom-pagination">{renderPaginationBars()}</div>
              <button className="right-nav-swiper-button" onClick={slideNext}>
                <ChevronRight size={25} />
              </button>
            </div>
          </div>
        </article>
      </section>
      {/* job-opportunities */}
      <div className="job-opportunities-main">
        <div className="job-opportunities-section">
          <div className="job-oppurtunities-section-main">
            <div className="job-opportunities-left">
              <div className="job-opportunities-left-main-text">
                Unlock Your Job Opportunities
              </div>
              <div className="job-opportunities-left-info">
                Achieve career success with our placement assistance program!
                TechU graduates have secured positions at top IT companies with
                impressive salary packages.
              </div>
              <div className="job-opportunities-job-titles">
                <div className="job-opportunities-job-titles-main">
                  <div className="job-opportunities-job-titles-main-01">
                    <div className="job-opportunities-job-titles-left-tick-bg">
                      <div className="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div className="job-opportunities-job-titles-right">
                      <div className="job-opportunities-job-title-name">
                        Data Scientist
                      </div>
                    </div>
                  </div>
                  <div className="job-opportunities-job-titles-main-01">
                    <div className="job-opportunities-job-titles-left-tick-bg">
                      <div className="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div className="job-opportunities-job-titles-right">
                      <div className="job-opportunities-job-title-name">
                        Machine Learning Engineer
                      </div>
                    </div>
                  </div>

                  <div className="job-opportunities-job-titles-main-01">
                    <div className="job-opportunities-job-titles-left-tick-bg">
                      <div className="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div className="job-opportunities-job-titles-right">
                      <div className="job-opportunities-job-title-name">
                        Data Analyst
                      </div>
                    </div>
                  </div>
                  <div className="job-opportunities-job-titles-main-01">
                    <div className="job-opportunities-job-titles-left-tick-bg">
                      <div className="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div className="job-opportunities-job-titles-right">
                      <div className="job-opportunities-job-title-name">
                        AI Research Scientist
                      </div>
                    </div>
                  </div>
                  <div className="job-opportunities-job-titles-main-01">
                    <div className="job-opportunities-job-titles-left-tick-bg">
                      <div className="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div className="job-opportunities-job-titles-right">
                      <div className="job-opportunities-job-title-name">
                        Business Intelligence (BI) Analyst
                      </div>
                    </div>
                  </div>
                  <div className="job-opportunities-job-titles-main-01">
                    <div className="job-opportunities-job-titles-left-tick-bg">
                      <div className="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div className="job-opportunities-job-titles-right">
                      <div className="job-opportunities-job-title-name">
                        Data Engineer
                      </div>
                    </div>
                  </div>
                  <div className="job-opportunities-job-titles-main-01">
                    <div className="job-opportunities-job-titles-left-tick-bg">
                      <div className="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div className="job-opportunities-job-titles-right">
                      <div className="job-opportunities-job-title-name">
                        AI Product Manager
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Job-opportunities-right">
              <div className="Job-opportunities-right-image-div">
                <img
                  loading="lazy"
                  className="Job-opportunities-right-image"
                  src={jobskill}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="batch-start-container-main">
          <div className="Book-free-demo-main">
            <div className="Book-free-demo-main-section">
              <div className="Book-free-demo-main">
                <button
                  id="Book-free-demo-main-id"
                  onClick={() => handleclick("hero-section")}
                  className="Book-free-demo"
                >
                  Book a Free Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* certificate section */}
      <section id="certificate" className="certificate-container">
        <div className="certificate-main-container-div">
          <div className="certificate-container-div-inner">
            <div className="certificate-container-div-inner-left">
              <div className="certificate-container-div-inner-left-heading-div">
                <h2 className="get-certified-heading">
                  Achieve Certifications
                </h2>
              </div>
              <div className="certificate-container-div-inner-left-description">
                Gain industry-recognized certifications to enhance your digital
                marketing skills and boost your employability.
              </div>
              <div className="certificate-content">
                {/* <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div-certificate">
                    <div className="certificate-content-txt">
                      SEO Certification
                    </div>
                  </div>
                </div> */}
                {/* <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div">
                    <div className="certificate-content-txt">
                      Bing Certification
                    </div>
                  </div>
                </div> */}
                <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div">
                    <div className="certificate-content-txt">
                      HubSpot Certification
                    </div>
                  </div>
                </div>
                {/* <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div">
                    <div className="certificate-content-txt">
                      Google Ads Certification
                    </div>
                  </div>
                </div> */}
                <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div">
                    <div className="certificate-content-txt">
                      Google  Certification
                    </div>
                  </div>
                </div>
                <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div">
                    <div className="certificate-content-txt">
                      Facebook  Certification
                    </div>
                  </div>
                </div>
                <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div">
                    <div className="certificate-content-txt">
                      YouTube Certification
                    </div>
                  </div>
                </div>
                {/* <div className="certificate-content-container">
                  <div className="certificate-content-div-certificate-tick-bg">
                    <div className="certificate-content-div-certificate">
                      <MdOutlineDone />
                    </div>
                  </div>
                  <div className="certificate-content-div">
                    <div className="certificate-content-txt">
                      Content Marketing Certification
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="certificate-container-div-inner-right">
              <div className="certificate-container-div-inner-right-image">
                <img
                  loading="lazy"
                  src={certificate}
                  alt="certificate"
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div className="batch-start-container-main">
            <div className="Book-free-demo-main">
              <div className="Book-free-demo-main-section">
                <div className="Book-free-demo-main">
                  <button
                    id="Book-free-demo-main-id"
                    onClick={() => handleclick("hero-section")}
                    className="Book-free-demo"
                  >
                    Book a Free Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="certification-section">
          <div className="certificate-bg">

            <div className="hubspot-certificate">
              <img
                className="tools-image-individual-certificate"
                loading="lazy"
                src={hubspotcertificate}
                alt="googleAds"
              />
              <p className="hubspotcertificate-txt">HubSpot Certification</p>
            </div>
          </div>

          <div className="certificate-bg">
            <div className="hubspot-certificate">
              <img
                className="tools-image-individual-certificate"
                loading="lazy"
                src={googleImg}
                alt="googleAds"
              />
              <p className="hubspotcertificate-txt">Google Certification</p>

            </div>
          </div>
          <div className="certificate-bg">
            <div className="hubspot-certificate">
              <img
                className="tools-image-individual-certificate "
                loading="lazy"
                src={facebookImg}
                alt="googleAds"
              />
              <p className="hubspotcertificate-txt">Facebook Certification</p>

            </div>
          </div>
          <div className="certificate-bg">
            <div className="hubspot-certificate">
              <img
                className="tools-image-individual-certificate"
                loading="lazy"
                src={youtubeImg}
                alt="googleAds"
              />
              <p className="hubspotcertificate-txt">Youtube Certification</p>

            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default TechuCompany;
